import React, { useState } from "react";
import styles from "./LeaderBoard.module.css";
import { ethers } from "ethers";
import axios from "axios";

const LeaderBoard = () => {
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);

  const getLeaderBoardData = async () => {
    // let url = "https://squid-app-zq7y8.ondigitalocean.app/leaderboard";
    // let { data } = await axios.get(url);

    // setData(data);

    let url = "https://api.thegraph.com/subgraphs/name/civa/uns-stake";
    let query = `{
      users(orderBy: totalReferralEarnings, orderDirection: desc, first: 1000) {
        id
        level1ReferralCount
        level2ReferralCount
        level1ReferralEarnings
        level2ReferralEarnings
        totalReferralEarnings
      }
    }`

    let { data } = await axios.post(url, { query });
    let users = data.data.users;
    let leaderboard = [];
    for (let i = 0; i < users.length; i++) {
      let user = users[i];
      let address = ethers.utils.getAddress(user.id);
      let level1ReferralCount = user.level1ReferralCount;
      let level2ReferralCount = user.level2ReferralCount;
      let level1ReferralEarnings = (user.level1ReferralEarnings);
      let level2ReferralEarnings = (user.level2ReferralEarnings)
      let totalReferralEarnings = Number(user.level1ReferralEarnings) + Number(user.level2ReferralEarnings);
      leaderboard.push({
        address,
        level1ReferralCount,
        level2ReferralCount,
        level1ReferralEarnings,
        level2ReferralEarnings,
        totalReferralEarnings
      })
    }
    setData(leaderboard);
  };
  getLeaderBoardData();
  // const data = [
  //   {
  //     address: "0xBF20B5AA204b5134c0F1d4e002f09B64b6c06554",
  //     level1ReferralCount: 2,
  //     level2ReferralCount: 0,
  //     level1ReferralEarnings: "164285714285714280000",
  //     level2ReferralEarnings: 0,
  //     totalReferralEarnings: "164285714285714280000",
  //   },
  //   {
  //     address: "0gBF20B5AA204b5134c0F1d4e002f09B64b6c06554",
  //     level1ReferralCount: 2,
  //     level2ReferralCount: 0,
  //     level1ReferralEarnings: "80000",
  //     level2ReferralEarnings: 0,
  //     totalReferralEarnings: "164285714285714280000",
  //   },
  // ];
  const filteredItems = data.filter((item) =>
    item.address.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className={styles.leaderBoard}>
      <div className="container">
        <div className={styles.titleAndSearchBox}>
          <h3 className={styles.title}>Referral LeaderBoard</h3>
          <input
            type="text"
            placeholder="Search by address"
            className={styles.searchInput}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <th className={styles.heading}>Rank</th>
                <th className={styles.heading}>Address</th>
                <th className={styles.heading}>Level 1</th>
                <th className={styles.heading}>Level 2 </th>
                <th className={styles.heading}>Level 1 Earnings</th>
                <th className={styles.heading}>Level 2 Earnings</th>
                <th className={styles.heading}>Total Earnings</th>
              </tr>
              {filteredItems.map((participant, index) => (
                <tr className={styles.row} key={index}>
                  <td className={styles.item}>{index + 1}</td>
                  <td

                    onClick={() => {
                      // window.location.href = `https://etherscan.io/address/${participant.address}`;    
                      window.location.href = `https://unsfamily.com/${participant.address}`;
                    }}
                    className={styles.item}>
                    {/* {String(participant.address).slice(0, 6) +
                      "...." +
                      String(participant.address).slice(-4)} */}
                    {participant.address}
                  </td>
                  <td className={styles.item}>
                    {participant.level1ReferralCount}
                  </td>
                  <td className={styles.item}>
                    {participant.level2ReferralCount}
                  </td>
                  <td className={styles.item}>
                    {" "}
                    {participant
                      ? (
                        Number(participant.level1ReferralEarnings) /
                        10 ** 18
                      ).toFixed(2) + " UNS"
                      : "null"}
                  </td>
                  <td className={styles.item}>
                    {" "}
                    {participant
                      ? (
                        Number(participant.level2ReferralEarnings) /
                        10 ** 18
                      ).toFixed(2) + " UNS"
                      : "null"}
                  </td>
                  <td className={styles.item}>
                    {participant
                      ? (
                        Number(participant.totalReferralEarnings) /
                        10 ** 18
                      ).toFixed(2) + " UNS"
                      : "null"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default LeaderBoard;

{
  /*   <div
              className={`${styles.row} ${
                index % 2 === 0 ? styles.even : styles.odd
              }`}
              key={index}
            >
              <p className={styles.item}>{participant.address}</p>
              <p className={styles.item}>{participant.level1ReferralCount}</p>
              <p className={styles.item}>{participant.level2ReferralCount}</p>
              <p className={styles.item}>
                {participant.level1ReferralEarnings}
              </p>
              <p className={styles.item}>
                {participant.level2ReferralEarnings}
              </p>
              <p className={styles.item}>{participant.totalReferralEarnings}</p>
            </div> */
}
